const apiUrl = function() {
	return "https://api.quizz.jason-david.fr/api/v1";
}

const postRequestOptions = {
	method: 'POST',
	headers: { 
		'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' 
	},
	mode: 'cors',
	withCredentials: true,
	credentials: 'include',
	simple: false
};

const putRequestOptions = {
	method: 'PUT',
	headers: { 
		'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' 
	},
	mode: 'cors',
	withCredentials: true,
	credentials: 'include',
};

const getRequestOptions = {
	withCredentials: true,
	credentials: 'include',
	mode: 'cors',
};

const getRequest = function(url) {
	return fetch(apiUrl() + url, getRequestOptions);
}

const postRequest = function(url, data) {
	postRequestOptions.body = transformDataIntoBody(data);

	return fetch(apiUrl() + url, postRequestOptions);
}

const putRequest = function(url, data) {
	putRequestOptions.body = transformDataIntoBody(data);

	return fetch(apiUrl() + url, putRequestOptions);
}

function transformDataIntoBody(data){
	var formBody = [];
	for (var property in data) {
		var encodedKey = encodeURIComponent(property);
		var encodedValue = encodeURIComponent(data[property]);
		formBody.push(encodedKey + "=" + encodedValue);
	}
	formBody = formBody.join("&");
	
	return formBody;
}


export { apiUrl, getRequest, postRequest, putRequest };