import React from 'react'
import { Route } from 'react-router-dom'
import { Switch } from 'react-router-dom'

import QuizzLayout from '../Quizz/Quizz'
import DoQuizzLayout from '../Quizz/DoQuizz'
import EndQuizzLayout from '../Quizz/EndQuizz'

const AuthorizedLayout = ({ match }) => (
	<div className="app authorized-layout">
		<Switch>
			<Route path="/quizz/:codeQuizz" exact component={QuizzLayout} />
			<Route path="/quizz/:codeQuizz/end" exact component={EndQuizzLayout} />
			<Route path="/quizz/:codeQuizz/:questionNumber" exact component={DoQuizzLayout} />
		</Switch>
	</div>
)

export default AuthorizedLayout