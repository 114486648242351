import React, { Component } from 'react';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardGroup, MDBContainer, MDBBtn, } from "mdbreact";

import './WebsocketAdmin.css';

class WebsocketAdmin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentPlayers: [],
			currentPlayersCards: [],
			codeQuizz: this.props.match.params.codeQuizz,
			quizzInfo: {},
			masterQuizzReady: false
		};
		this.initConnection();
	}

	initConnection = () => {
		this.state.client = new W3CWebSocket('wss://wss.quizz.jason-david.fr');
		this.state.contentDefaultMessage = "Hello there";
	}

	logInUser = () => {
		const playerName = "admin";
		const codeQuizz = this.state.codeQuizz

		if (playerName.trim()) {
			const data = {
				codeQuizz
			};
			this.setState({
				...data
			}, () => {
				this.state.this.state.client.send(JSON.stringify({
					...data,
					type: "joinquizz"
				}));
			});
		}
	}
	
	componentDidMount() {
		this.state.client.onopen = () => {
			console.log('WebSocket Client Connected');
			this.state.client.send(JSON.stringify({
				type: "init",
				codeQuizz: this.state.codeQuizz
			}));
		};
		this.state.client.onmessage = (message) => {
			const dataFromServer = JSON.parse(message.data);

			if (dataFromServer.type === "init") {
				console.log("init", dataFromServer.data)

				if (this.state.currentPlayers && this.state.currentPlayers.length > 0) {
					const codeQuizz = this.state.codeQuizz;
					this.setState(prevState => ({
						currentPlayers: prevState.currentPlayers.map(function(player) {
							const receivedPlayerData = dataFromServer.data.players[codeQuizz].filter(p => p.ID === player.ID)[0];
							player.LAST_QUESTION = receivedPlayerData.LAST_QUESTION;
							return player;
						  })
					}))
				} else {
					this.setState(() => {
						return { currentPlayers: dataFromServer.data.players[this.state.codeQuizz] }
					});
				}
			} else if (dataFromServer.type === "joinquizz") {
				if(!this.state.currentPlayers){
					this.setState({ currentPlayers: [ dataFromServer.data] })
				} else {
					const existingCurrentPlayer = this.state.currentPlayers.filter(cp => cp.ID === dataFromServer.data.ID);
					// Not already stored in memory
					if(existingCurrentPlayer.length === 0){
						this.setState({ currentPlayers: [...this.state.currentPlayers, dataFromServer.data] })
					} else {
						this.setState(prevState => ({
							currentPlayers: prevState.currentPlayers.map(
								cp => cp.ID === dataFromServer.data.ID ? { ...cp, LAST_QUESTION: dataFromServer.data.LAST_QUESTION } : cp
							)
						}))
					}
				}
			} else if (dataFromServer.type === "answerquestion") {
				this.setState(prevState => ({
					currentPlayers: prevState.currentPlayers.map(
						cp => cp.ID === dataFromServer.data.PLAYER_ID ? { ...cp, lastAnswer: dataFromServer.data } : cp
					)
				}))
			} else if (dataFromServer.type === "displayresult") {
				console.log(dataFromServer.data)
				this.setState(prevState => ({
					currentPlayers: prevState.currentPlayers.map(
						cp => cp.ID == dataFromServer.data.playerId ? { ...cp, result: dataFromServer.data } : cp
					)
				}))
			} else if (dataFromServer.type === "adminfo") {
				console.log("admininfo", dataFromServer.data)
				this.setState(() => ({
					quizzInfo: dataFromServer.data
				}))
			} else if (dataFromServer.type === "playerdisconnected") {
				console.log("playerdisconnected", dataFromServer.data)
				this.setState(prevState => ({
					currentPlayers: (prevState.currentPlayers) ? prevState.currentPlayers.map(
						cp => cp.PLAYER_NAME === dataFromServer.data.playerName ? { ...cp, online: false } : cp
					) : []
				}))
			} else if (dataFromServer.type === "playerconnected") {
				console.log("playerconnected", dataFromServer.data)
				this.setState(prevState => ({
					currentPlayers: prevState.currentPlayers.map(
						cp => cp.PLAYER_NAME === dataFromServer.data.playerName ? { ...cp, online: true } : cp
					)
				}))
			}
		};
	}

	render() {
		const readyToStart = () => {
			this.state.client.send(JSON.stringify({
				type: "masterreadystart"
			}));
			this.setState(prevState => ({
				masterQuizzReady: true
			}))
		}

		const deleteSession = () => {
			this.state.client.send(JSON.stringify({
				type: "cleanquizzsession"
			}));
		}

		const renderResult = (currentPlayer) => {
			if(currentPlayer.result){
				return (<span>| Score: {currentPlayer.result.score}/{currentPlayer.result.totalQuestion}</span>)
			}
		} 
		
		const renderCurrentQuestion = (currentPlayer) => {
			let currentQuestion;
			if(currentPlayer.lastAnswer){
				currentQuestion = currentPlayer.lastAnswer.QUESTION_NUMBER * 1 + 1
			} else {
				currentQuestion = currentPlayer.LAST_QUESTION * 1 + 1
			}

			return (<span>Current question: <strong>{currentQuestion}</strong></span>)
		} 
		
		const renderLastAnswer = (currentPlayer) => {
			if(currentPlayer.lastAnswer){
				return (<span>Last answer: <strong>{currentPlayer.lastAnswer.PLAYER_ANSWER}</strong> on question <strong>{currentPlayer.lastAnswer.QUESTION_NUMBER}</strong></span>)
			}
		}

		const playerStatusOffline = (currentPlayer) => {
			return !currentPlayer.hasOwnProperty('online') || currentPlayer.online === false
		}

		const getClassNames = (currentPlayer) => {
			if(playerStatusOffline(currentPlayer)){
				return "bg-warning player-offline"
			}

			return "bg-primary player-online"
		}
		
		const renderCards = () => {
			if (this.state.currentPlayers) {
				this.state.currentPlayersCards = [];
				let cards = this.state.currentPlayersCards;

				for (let i = 0; i <= this.state.currentPlayers.length / 3; i++) {
					let row = [];
					for (let j = (i * 3); j < (i + 1) * 3; j++) {
						if (j < this.state.currentPlayers.length) {
							row.push(
								<MDBCard key={this.state.currentPlayers[j].ID} id={this.state.currentPlayers[j].ID} className={ getClassNames(this.state.currentPlayers[j]) }>
									<MDBCardHeader>{this.state.currentPlayers[j].PLAYER_NAME} {renderResult(this.state.currentPlayers[j])}</MDBCardHeader>
									<MDBCardBody>
										<MDBCardText>
											{renderCurrentQuestion(this.state.currentPlayers[j])}
											{renderLastAnswer(this.state.currentPlayers[j])}
										</MDBCardText>
									</MDBCardBody>
								</MDBCard>
							)
						}
					}

					cards.push(<MDBCardGroup key={i} deck>{row}</MDBCardGroup>)
				}

				return cards;
			}
		}

		const renderQuizzInfo = () => {
			if(Object.keys(this.state.quizzInfo).length !== 0 && this.state.quizzInfo && this.state.quizzInfo.quizzSession && this.state.quizzInfo.quizzSession.startState){
				return <MDBCard key="quizzInfo" id="quizzInfo" style={{margin: "5px 0 5px 0"}}>
							<MDBCardBody>
								<MDBCardTitle tag="h5">Quizz info {this.state.quizzInfo.quizzSession.codeQuizz}</MDBCardTitle>
								<MDBCardText>
									<span>Quizz state: <strong>{this.state.quizzInfo.quizzSession.startState.toString()}</strong></span>
									<span>Highest Question: <strong>{this.state.quizzInfo.quizzSession.highestQuestion}</strong></span>
									<span>Smallest Question: <strong>{this.state.quizzInfo.quizzSession.smallestQuestion}</strong></span>
								</MDBCardText>

								<MDBBtn onClick={() => deleteSession()} color='warning' style={{margin: "5px 0 5px 0"}}>Delete info quizz in memory</MDBBtn>
							</MDBCardBody>
						</MDBCard>
			}
		}

		const renderButton = () => {
			if((this.state.quizzInfo && this.state.quizzInfo.quizzSession && !this.state.quizzInfo.quizzSession.startState) && !this.state.masterQuizzReady){
				return <MDBBtn onClick={() => readyToStart()} color='info' style={{margin: "5px 0 5px 0"}}>Ready to start quizz</MDBBtn>
			}
		}

		return (
			<MDBContainer>
				{renderButton()}

				{renderQuizzInfo()}

				{renderCards()}
			</MDBContainer>
		);
	}
}

export default WebsocketAdmin;