import React, { Component } from 'react';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardGroup, MDBContainer } from "mdbreact";

import { getRequest } from '../API/API';
import './QuizzResults.css';

class QuizzResults extends Component {
	constructor(props) {
		super(props);
		this.state = {
			results: [],
			currentPlayersCards: [],
			codeQuizz: this.props.match.params.codeQuizz
		};
	}

	componentDidMount() {
		getRequest("/quizz/code/" + this.state.codeQuizz + "/players/results")
			.then(response => response.json())
			.then(results => {
				this.setState({ results: results })
			})
			.catch(() => {
				
			});
	}

	render() {
		const renderResult = (player) => {
			if(player.RESULT.data){
				return (<span>Score: {player.RESULT.data.score}/{player.RESULT.data.totalQuestion}</span>)
			}
		}
		
		const renderCards = () => {
			if (this.state.results.length > 0) {
				let cards = this.state.currentPlayersCards;
				
				for (let i = 0; i <= this.state.results.length / 3; i++) {
					let row = [];
					for (let j = (i * 3); j < (i + 1) * 3; j++) {
						if (j < this.state.results.length) {
							row.push(
								<MDBCard key={this.state.results[j].ID} id={this.state.results[j].ID}>
									<MDBCardBody>
										<MDBCardTitle tag="h5">{this.state.results[j].PLAYER_NAME}</MDBCardTitle>
										<MDBCardText>
											{renderResult(this.state.results[j])}
										</MDBCardText>
									</MDBCardBody>
								</MDBCard>
							)
						}
					}

					cards.push(<MDBCardGroup key={i} deck>{row}</MDBCardGroup>)
				}

				return cards;
			}
		}

		return (
			<MDBContainer>
				{renderCards()}
			</MDBContainer>
		);
	}
}

export default QuizzResults;