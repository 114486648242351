import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap';

import RouterUtils from './utils/RouterUtils'
import QuizzForm from './QuizzForm/QuizzForm'
import AuthorizedLayout from './Layouts/AuthorizedLayout'
import AuthorizedRoute from './utils/AuthorizedRoute'
import WebsocketAdmin from './WebsocketAdmin/WebsocketAdmin'
import QuizzResults from './QuizzResults/QuizzResults'

const Router = () => (
	<Container>
		<Row>
			<Col></Col>
			<Col xs={12} sm={8} md={8} lg={8}>
				<RouterUtils>
					<Switch>
						<Route path="/ws/:codeQuizz" component={WebsocketAdmin} />
						<Route path="/results/:codeQuizz" component={QuizzResults} />
						<Route path="/join-quizz/:codeQuizz" component={QuizzForm} />
						<Route path="/join-quizz" component={QuizzForm} />
						<AuthorizedRoute path="/quizz/:codeQuizz" component={AuthorizedLayout} />
						<Redirect to="/join-quizz" />
					</Switch>
				</RouterUtils></Col>
			<Col></Col>
		</Row>
	</Container>
)

export default Router;