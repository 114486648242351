import React, { useEffect, useState } from 'react'
import { MDBCard, MDBCardBody, MDBCardHeader, MDBContainer } from "mdbreact";

import { useAuthUser } from '../utils/AuthUser'
import { getRequest } from '../API/API';

const EndQuizzLayout = ({ match, history }) => {
	const [score, setScore] = useState({})
	const { playerData } = useAuthUser()
	const { codeQuizz } = match.params

	useEffect(() => {
		let isCurrent = true
		if (playerData.playerName) {
			getRequest("/quizz/code/" + codeQuizz + "/player/" + playerData.playerName + "/result")
				.then(response => response.json())
				.then(score => {
					if (isCurrent) {
						setScore(score)
					}
				})
				.catch(() => {
					history.push('/join-quizz');
				})
		}

		return () => (isCurrent = false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [codeQuizz, playerData])

	return (
		<MDBContainer>
			<MDBCard style={{ width: "100%", marginTop: "1rem" }}>
				<MDBCardHeader color="info-color">End of the Quizz!</MDBCardHeader>
				<MDBCardBody className="quizz-answers">
					You performed {score.score} on {score.totalQuestion}
				</MDBCardBody>
			</MDBCard>
		</MDBContainer>
	)
}

export default EndQuizzLayout