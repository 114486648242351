import React, { Component } from 'react';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { useAuthUser } from '../utils/AuthUser'

import { MDBBtn } from "mdbreact";

class WebsocketClient extends Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			currentPlayers: [],
			currentPlayersCards: [],
			codeQuizz: props.props.codeQuizz,
			questionNumber: parseInt(props.props.questionNumber, 10),
			quizzCanStart: (props.props.questionNumber > 1) ? true : false,
			readyToStart: (props.props.questionNumber > 1) ? true : false,
			highestQuizz: (props.props.questionNumber > 1) ? props.props.questionNumber : 1,
			smallestQuizz: (props.props.questionNumber > 1) ? props.props.questionNumber : 1,
		};
		this.initConnection();
	}

	initConnection = () => {
		this.state.client = new W3CWebSocket('wss://wss.quizz.jason-david.fr');
		this.state.contentDefaultMessage = "Hello there";
		console.log("Init connection")

		this.state.client.onopen = () => {
			console.log('WebSocket Client Connected');
			this.state.client.send(JSON.stringify({
				type: "init",
				codeQuizz: this.state.codeQuizz,
				questionNumber: this.state.questionNumber,
				playerName: this.props.playerName
			}));
		};
		this.state.client.onmessage = (message) => {
			const dataFromServer = JSON.parse(message.data);

			if (dataFromServer.type === "init") {
				this.setState(() => {
					return { currentPlayers: dataFromServer.data.players[this.state.codeQuizz] }
				});
			} else if (dataFromServer.type === "joinquizz") {
				if(!this.state.currentPlayers){
					this.setState({ currentPlayers: [ dataFromServer.data] })
				} else {
					this.setState({ currentPlayers: [...this.state.currentPlayers, dataFromServer.data] })
				}
			} else if (dataFromServer.type === "answerquestion") {
				this.setState(prevState => ({
					currentPlayers: prevState.currentPlayers.map(
						cp => cp.ID === dataFromServer.data.PLAYER_ID ? { ...cp, lastAnswer: dataFromServer.data } : cp
					)
				}))
			} else if (dataFromServer.type === "displayresult") {
				console.log(dataFromServer.data)
				this.setState(prevState => ({
					currentPlayers: prevState.currentPlayers.map(
						cp => cp.ID == dataFromServer.data.playerId ? { ...cp, result: dataFromServer.data } : cp
					)
				}))
			} else if (dataFromServer.type === "quizzcanstart") {
				this.setState(prevState => ({
					quizzCanStart: dataFromServer.data.canQuizzStart,
					smallestQuizz: dataFromServer.data.smallestQuestion
				}))
				this.props.updateQuizzReady(dataFromServer.data.canQuizzStart)
				this.props.updateCanReply((this.state.questionNumber <= this.state.smallestQuizz))
			}
		};
		this.state.client.onclose = () => {
			console.log('Socket is closed. Reconnect will be attempted in 1 second.');
			setTimeout(
				function() {
					this.initConnection(this.props);
				}
				.bind(this),
				1000
			);
		};
		
		this.state.client.onerror = () => {
			console.error('Socket encountered error. Closing socket');
			this.state.client.close();
		};
	}

	logInUser = () => {
		const playerName = "bloblo";
		const codeQuizz = this.state.codeQuizz

		if (playerName.trim()) {
			const data = {
				codeQuizz
			};
			this.setState({
				...data
			}, () => {
				this.state.this.state.client.send(JSON.stringify({
					...data,
					type: "joinquizz"
				}));
			});
		}
	}
	
	componentDidMount() {
	}

	componentDidUpdate() {
	}

	sendCurrentQuestion = (currentQuestion) => {
		this.state.client.send(JSON.stringify({
			type: "currentquestion",
			data: currentQuestion
		}));

		this.setState(prevState => ({
			questionNumber: currentQuestion
		}))
	}

	render() {
		const readyToStart = () => {
			this.state.client.send(JSON.stringify({
				type: "readystart"
			}));
			this.state.readyToStart = true
		}

		const renderReady = () => {
			if(this.state.quizzCanStart){
				if(this.state.questionNumber > this.state.smallestQuizz){
					return <h2>Waiting other players to answer...</h2>
				}
			} else {
				if(!this.state.readyToStart){
					return (
						<MDBBtn onClick={() => readyToStart()} color="info-color">Ready to start quizz</MDBBtn>
					)
				} else {
					return <h2>Waiting other players to start...</h2>
				}
			}
		}
		
		return (
			<div>
				{renderReady()}
			</div>
		);
	}
}

export default WebsocketClient;