import React, { useEffect, useState } from 'react'
import { MDBCard, MDBCardBody, MDBCardHeader, MDBBtn, MDBContainer, MDBInput } from "mdbreact";
import { useAuthUser } from '../utils/AuthUser'

import './doQuizz.css';
import { getRequest, putRequest } from '../API/API';
import WebsocketClient from '../Websocket/Websocket'

const DoQuizzLayout = ({ match, history }) => {
	const { logged, playerData } = useAuthUser()
	const [question, setQuestion] = useState({})
	const { codeQuizz, questionNumber } = match.params
	const [answers, setAnswers] = useState([]);
	const [reply, setReply] = useState([])
	const [quizzReady, setQuizzReady] = useState(true)
	const [canReply, setCanReply] = useState(true)
	const myRef = React.createRef()

	useEffect(() => {
		let isCurrent = true
		getRequest("/auth")
			.then(response => response.json())
			.then(data => {
				// Protect if user opens question 1 while he is doing higher question
				// Allows to go back once
				const userCurrentQuestion = Number(data.currentQuestionNumber)
				const questionNumberAsNumber = Number(questionNumber)
				if((userCurrentQuestion + 1) !== questionNumberAsNumber && (userCurrentQuestion - 1) !== questionNumberAsNumber && userCurrentQuestion !== questionNumberAsNumber){
					history.push('/quizz/' + data.quizzCode.toUpperCase() + '/' + (userCurrentQuestion + 1));
				} else {
					getRequest("/quizz/code/" + codeQuizz + "/question/" + questionNumber)
					.then(response => response.json())
					.then(async quizz => {
						if (quizz.status === 'error') {
							history.push('/join-quizz');
						} else {
							if (isCurrent) {
								setQuestion(quizz.data)

								setAnswersItems(shuffleArray(quizz.data.QUESTIONS), quizz.data.multipleAnswers)

								setReply([])
								const response = await getRequest("/question/" + questionNumber);
								if (response.status === 400) {
									window.location.reload();
								}
								const answer = await response.json()
								if (answer.status !== 'error') {
									setReply(answer.data.PLAYER_ANSWER)
								}
							}
						}
					})
					.catch(() => {
						history.push('/join-quizz');
					})
				}
			});
		return () => (isCurrent = false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [codeQuizz, questionNumber, history, logged])

	useEffect(() => {
		if (question) {
			setAnswersItems(question.QUESTIONS, question.multipleAnswers)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reply, history])

	const setAnswersItems = (questions, multipleAnswers) => {
		if (questions) {
			const answers = [];
			questions.forEach(function(value, index) {
				if (multipleAnswers){
					answers.push(<MDBInput onClick={onClick(value.questionId)} checked={reply.includes(value.questionId)} label={value.questionId + '. ' + value.questionLabel} type="checkbox" key={index} id={'radio' + index} />)
				} else {
					answers.push(<MDBInput onClick={onClick(value.questionId)} checked={reply.includes(value.questionId)} label={value.questionId + '. ' + value.questionLabel} type="radio" key={index} id={'radio' + index} />)
				}
			});
			setAnswers(answers)
		}
	}

	const onClick = nr => () => {
		if (question.multipleAnswers){
			if(reply.indexOf(nr) === -1){
				setReply(reply => [...reply, nr] )
			 } else {
				setReply(reply.filter(function(r) { 
					return r !== nr
				}))
			 }
		} else {
			setReply(nr)
		}
	}

	const next = url => () => {
		var body = {
			'answer': reply
		};

		putRequest("/question/" + question.QUESTION_NUMBER, body)
			.then(response => response.json())
			.then(() => {
				myRef.current.sendCurrentQuestion((question.QUESTION_NUMBER + 1))

				history.push(url);
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const previous = url => () => {
		myRef.current.sendCurrentQuestion((question.QUESTION_NUMBER - 1))

		history.push(url);
	}

	function shuffleArray(array) {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}
	}

	function previousButton() {
		if (question.QUESTION_NUMBER > 1) {
			const previousPage = question.QUESTION_NUMBER * 1 - 1;
			return (
				<MDBBtn onClick={previous('/quizz/' + codeQuizz + '/' + previousPage)} className="float-left" color="info-color">Previous</MDBBtn>
			)
		}
	}


	function nextButton() {
		if (question.QUESTION_NUMBER === question.NUMBER_QUESTIONS) {
			return (
				<MDBBtn onClick={next('/quizz/' + codeQuizz + '/end')} className="float-right" disabled={reply.length > 0 ? false : true} color="success-color">Finish</MDBBtn>
			)
		} else {
			const nextPage = question.QUESTION_NUMBER * 1 + 1;
			return (
				<MDBBtn onClick={next('/quizz/' + codeQuizz + '/' + nextPage)} disabled={reply.length > 0 ? false : true} className="float-right" color="info-color">Next</MDBBtn>
			)
		}
	}

	function updateQuizzReady(quizzReady){
		setQuizzReady(quizzReady)
	}

	function updateCanReply(canReply){
		setCanReply(canReply)
	}

	function displayQuestions(){
		if(quizzReady && canReply){
			return (
				<div>
					<div id="question-value">
						{question.QUESTION_VALUE}
					</div>
					{answers}
					<div className="quizz-buttons">
						{previousButton()}
						{nextButton()}
					</div>
				</div>
			)
		}
	}

	function dipslayWebSocket(){
		return <WebsocketClient key={logged} playerName={playerData.playerName} logged={logged} updateQuizzReady={updateQuizzReady} updateCanReply={updateCanReply} ref={myRef} props={match.params}/>
	}

	return (
		<MDBContainer>
			<MDBCard style={{ width: "100%", marginTop: "1rem" }}>
				<MDBCardHeader color="info-color">Question {question.QUESTION_NUMBER}/{question.NUMBER_QUESTIONS} ({question.expectedAnswers} réponse(s))</MDBCardHeader>
				<MDBCardBody className="quizz-answers">
					{dipslayWebSocket()}
					
					{displayQuestions()}
				</MDBCardBody>
			</MDBCard>
		</MDBContainer>
	)
}

export default DoQuizzLayout