import React, { useEffect, useState } from 'react'

import { MDBCard, MDBCardBody, MDBCardHeader, MDBContainer, MDBInput, MDBBtn, MDBIcon, MDBAlert } from "mdbreact";
import { getRequest, postRequest } from '../API/API';
import { useAuthUser } from '../utils/AuthUser'

import './QuizzForm.css';

const QuizzForm = ({ match, history }) => {
	const { logged, setLogged } = useAuthUser()
	const [isQuizzNotFound, setIsQuizzNotFound] = useState(false)
	const [isError, setIsError] = useState(false)
	const { codeQuizz } = match.params

	if (logged) {
		getRequest("/auth")
			.then(response => response.json())
			.then(data => {
				if (data.playerState.status === 'finished') {
					getRequest("/player/disconnect")
						.then(response => response.json())
						.then(() => {
							history.push('/join-quizz');
						});
					setLogged(false);
				} else if(data.playerState === 'off') {
					setLogged(false);
				} else {
					history.push('/quizz/' + data.quizzCode.toUpperCase() + '/' + data.currentQuestionNumber);
				}
			});

		return (<>
			<div className="spinner-border" role="status">
				<span className="sr-only">Loading...</span>
			</div>
		</>);
	} else {
		function handleSubmit(e) {
			e.preventDefault()

			const [quizzCodeNode, playerNameNode] = e.target.elements

			var body = {
				'playerName': playerNameNode.value
			};

			postRequest("/quizz/code/" + quizzCodeNode.value.toUpperCase() + "/player", body)
				.then((response) => {
					if(response.ok){
						setLogged(true)
						history.push('/quizz/' + quizzCodeNode.value.toUpperCase());
					} else if(response.status === 404) {
      					setLogged(false);
						setIsQuizzNotFound(true);
					}
				})
				.catch(() => {
					setIsError(true);
					setLogged(false)
				})
		}
		
		function Alert() {
		  return <MDBAlert color="danger">
			    	Error!
			    </MDBAlert>;
		}
		
		function Warning() {
		  return <MDBAlert color="warning">
			    	Quizz not found!
			    </MDBAlert>;
		}
		
		function Popup(props){
			if(props.isQuizzNotFound){
				return <Warning />;
			}
			if(props.isError){
				return <Alert />;
			}
			
			return null;
		}

		return (
			<MDBContainer>
				<Popup isError={isError} isQuizzNotFound={isQuizzNotFound} />
				
				<MDBCard style={{ width: "100%", marginTop: "1rem" }}>
					<MDBCardHeader color="info-color">Welcome!</MDBCardHeader>
					<MDBCardBody className="quizz-form">
						<form onSubmit={handleSubmit}>
							<p className="h5 text-center mb-4">Join Quizz</p>
							<div className="grey-text">
								<MDBInput label="Quizz Code" icon="lock" group type="text" validate error="wrong"
									success="right" name="codeQuizz" value={codeQuizz} required autoFocus 
									onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}/>
								<MDBInput label="Your Name" icon="user" group type="text" validate error="wrong"
									success="right" name="playerName" required />
							</div>
							<div className="text-center">
								<MDBBtn type="submit" outline color="info">
									Join
	            				<MDBIcon icon="play" className="ml-1" />
								</MDBBtn>
							</div>
						</form>
					</MDBCardBody>
				</MDBCard>
			</MDBContainer>
		)
	}
}

export default QuizzForm