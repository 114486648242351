import React, { useState, useContext, useEffect } from 'react'
import Cookies from 'js-cookie'

import { getRequest } from '../API/API';

export const AuthUserContext = React.createContext()

export const AuthUserProvider = ({ children }) => {
	const cookieLogged = Cookies.getJSON("playerName")
	const [logged, setLogged] = useState(cookieLogged ? true : false)
	const [playerData, setPlayerData] = useState({})
	
	useEffect(() => {
		async function isAuth() {
			await getRequest("/auth")
				.then(response => response.json())
				.then(data => {
					setPlayerData(data);

					if (data.playerName) {
						setLogged(true);
					} else {
						setLogged(false);
					}
				})
				.catch(error => {
					console.log(error)
				});
		}
		isAuth();
	}, [logged]);

	return <AuthUserContext.Provider value={{ logged, setLogged, playerData, setPlayerData }}>{children}</AuthUserContext.Provider>
}

export const useAuthUser = () => {
	return useContext(AuthUserContext)
}