import React, { useEffect, useState } from 'react'
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardHeader, MDBBtn, MDBContainer } from "mdbreact";

import { useAuthUser } from '../utils/AuthUser'
import { getRequest } from '../API/API';

const QuizzLayout = ({ match, history }) => {
	const [quizz, setQuizz] = useState(false)
	const { logged, playerData } = useAuthUser()
	const { codeQuizz } = match.params

	useEffect(() => {
		if(!logged){
			history.push('/join-quizz');
		}
		
		let isCurrent = true
		getRequest("/quizz/code/" + codeQuizz)
			.then(response => response.json())
			.then(quizz => {
				if (quizz.status === 'error') {
					history.push('/join-quizz');
				} else {
					if (isCurrent) {
						setQuizz(quizz.data)
					}
				}
			})
			.catch(() => {
				history.push('/join-quizz');
			})

		return () => (isCurrent = false)
	}, [codeQuizz, history])

	return (
		<MDBContainer>
			<MDBCard style={{ width: "100%", marginTop: "1rem" }}>
				<MDBCardHeader color="info-color">{quizz.NAME}</MDBCardHeader>
				<MDBCardBody>
					<MDBCardTitle>Code quizz {quizz.CODE}</MDBCardTitle>
					<MDBCardText>
						Welcome {playerData.playerName}! Are you ready to answer the next {quizz.NUMBER_QUESTIONS} questions ?
      				</MDBCardText>
					<MDBBtn href={'/quizz/' + quizz.CODE + '/1'} color="info-color">
						sTART
      				</MDBBtn>
				</MDBCardBody>
			</MDBCard>
		</MDBContainer>
	)
}

export default QuizzLayout