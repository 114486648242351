import React, { useState } from 'react';
import {
	MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBCollapse, MDBBtn, MDBNavbarToggler
} from "mdbreact";

import { useAuthUser } from '../utils/AuthUser'
import { getRequest } from '../API/API';

const disconnectCall = (setLoggedFalse) => {
	try {
		getRequest("/player/disconnect")
			.then(response => response.json())
			.then(() => {
				setLoggedFalse()
			});
	} catch (error) {
		console.log(error);
	}
}

const NavbarBlo = () => {
	const { logged, setLogged, playerData } = useAuthUser()
	const [open, setOpen] = useState(false);
	
	const disconnect = () => {
		disconnectCall(setLoggedFalse);
	}

	const setLoggedFalse = () => {
		setLogged(false);

		window.location.reload();
	}
	
	return (
		<MDBNavbar color="indigo" dark expand="md">
			<MDBNavbarBrand>
				<strong className="white-text">Jason Quizz</strong>
			</MDBNavbarBrand>
			<MDBNavbarToggler onClick={() => setOpen(!open)} />
			<MDBCollapse id="navbarCollapse3" isOpen={open} navbar>
				<MDBNavbarNav left>
					<MDBNavItem active>
						{logged && (playerData.playerName)}
					</MDBNavItem>
				</MDBNavbarNav>
				<MDBNavbarNav right>
					<MDBNavItem>
						{logged && (<MDBBtn outline size="sm" onClick={() => disconnect()}>Disconnect</MDBBtn>)}
					</MDBNavItem>
				</MDBNavbarNav>
			</MDBCollapse>
		</MDBNavbar>
	);
}

export default NavbarBlo;